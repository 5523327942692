html,
body,
#root {
	height: 100%;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 99%;
	background-position-y: 4px;
}

p,
span,
label,
input {
	font-family: "Montserrat", sans-serif;
}

h1 {
	font-family: "Montserrat", sans-serif;
	font-size: 3.8em;
	line-height: 1em;
}

h2 {
	font-family: "Montserrat", sans-serif;
	font-size: 2.5em;
}

.menu a {
	padding: 0px 15px;
	color: white;
	font-family: "Montserrat", sans-serif;
	text-decoration: none;
	font-size: 16px;
	font-weight: 700;
}

.numbers {
	font-family: "Montserrat", sans-serif;
	font-size: 30px;
}

button {
	transition: all 0.2s;
	cursor: pointer;
}
button:hover {
	transform: scale(1.01);
	transition: all 0.2s;
}
.courseCard {
	transition: all 0.2s;
	color: black;
	text-decoration: none;
	cursor: pointer;
}
.courseCard:hover {
	transform: scale(1.04);
	transition: all 0.2s;
}

footer {
	display: flex;
	margin-top: 50px;
	background-color: #141b59;
	padding: 15px 20px;
	align-items: center;
	justify-content: center;
}

footer a {
	color: white;
	text-decoration: none;
	margin: 0px 10px;
}

.resizer {
	width: 5px;
	background-color: #141b59;
}

[class="25"]{
	width: 25%;
	padding-right:10px;
	box-sizing: border-box;
}
[class="50"]{
	width: 50%;
	padding-right:10px;
	box-sizing: border-box;
}
[class="75"]{
	width: 75%;
	padding-right:10px;
	box-sizing: border-box;
}
[class="100"]{
	width: 100%;
	padding-right:10px;
	box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
	[class="25"]{
		width: 100%;
		padding-right:0px;
	}
	[class="50"]{
		width: 100%;
		padding-right:0px;
	}
	[class="75"]{
		width: 100%;
		padding-right:0px;
	}
	[class="100"]{
		width: 100%;
		padding-right:0px;
	}
}